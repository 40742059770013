import router from "@/router/index";
import firebase from "@/firebase/firebaseInit";
import C from "@/const";
const auth = firebase.auth;

const state = {
  user: null,
  claims: null,
  username: "",
  loggedIn: false,
  newRoute: null,
};
const getters = {
  getAuthUser: (state) => {
    return state.user;
  },
  getClaims: (state) => {
    return state.claims;
  },
  getName: (state) => {
    return state.username;
  },
  getAuth: (state) => {
    return state.loggedIn;
  },
  getNewRoute: (state) => {
    return state.newRoute;
  },
  getUserTypeString: (state) => {
    var userType = null;
    const claims = state.claims;
    if (claims) {
      if (claims.admin)
        userType = C.TYPE_INDEX[C.TYPE.ADMIN];
      else if (claims.owner)
        userType = C.TYPE_INDEX[C.TYPE.OWNER];
      else if (claims.press)
        userType = C.TYPE_INDEX[C.TYPE.PRESS];
      else if (claims.member)
        userType = C.TYPE_INDEX[C.TYPE.MEMBER];
    }

    return userType;
  },
  getUserType: (state) => {
    var userType = null;
    const claims = state.claims;
    if (claims) {
      if (claims.admin)
        userType = C.TYPE.ADMIN;
      else if (claims.owner)
        userType = C.TYPE.OWNER;
      else if (claims.press)
        userType = C.TYPE.PRESS;
      else if (claims.member)
        userType = C.TYPE.MEMBER;
    }

    return userType;
  },
  
  getUserVerified: (state) => {
    if (!state.user)
      return false;
    return state.user.emailVerified;
  }

};
const actions = {
  updateUser({ commit, dispatch }, user) {
    commit("setUserProfile", user);
    if (!user) {
      commit("setUsername", "");
      commit("setClaims", null);
      commit("logout");
    } else {
      commit("setUsername", user.displayName);
      commit("login");
    }
    dispatch('updateClaims');
  },
  setNewRouteAfterAuthStateChanged({ commit }, newRoute) {
    commit("setNewRoute", newRoute);
  },

  getClaims({ dispatch, commit, getters }) {
    return new Promise(function (reject, resolve) {
      let claims = getters.getClaims;
      console.log("CLAIMS: ");
      console.log(claims);

      if (!auth.currentUser) {
        console.log("no auth when getting claims");
        commit("setClaims", null);
        resolve(null);
        return;
      }
      if (!claims) {
        dispatch('updateClaims').then(() => {
          console.log("got claims, resolving promise: " + claims);
          let updatedClaims = getters.getClaims;
          if (!updatedClaims) {
            console.log("USER IS MISSING CLAIMS!");
            reject();
            return;
          }
          resolve(updatedClaims);
        });
        return;
      }

      console.log("claims were ready" + claims);
      resolve(claims);
    })
  },

  /**
   * Updates the claims in state
   * @returns a promise with the claims in case we need to wait for a response from server
   */
  async updateClaims({ commit, getters }) {
    var newRoute = getters.getNewRoute;
    commit("setNewRoute", null);
    if (!auth.currentUser) {
      // console.log("no auth => no claims");
      commit("setClaims", null);

      if (newRoute === "Login") {
        router.replace({ name: newRoute });
      }

      return null;
    }

    const idTokenResult = await auth.currentUser.getIdTokenResult(true);
    // console.log("user: " + auth.currentUser.email);
    // console.log("isPress: " + idTokenResult.claims.press);
    // console.log("claims: " + idTokenResult.claims);
    // console.log(idTokenResult.claims);

    // TODO: handle case where user signed up and is waiting for backend to set claims !!!!


    if (!idTokenResult.claims || (!idTokenResult.claims.admin && !idTokenResult.claims.member && !idTokenResult.claims.press && !idTokenResult.claims.owner)) {
      console.log("no proper claims!:");
      // console.log(idTokenResult);
      return Promise.resolve(null);
    }

    commit("setClaims", idTokenResult.claims);
    // console.log("redirecting to: " + newRoute);
    if (newRoute) {
      // console.log("next route: " + newRoute);
      commit("setNewRoute", null);
      if (newRoute === "Dashboard" && idTokenResult.claims) {
        // specify correct dashboard based on claims after login
        var userType = "";
        if (idTokenResult.claims.admin)
          userType = C.TYPE_INDEX[C.TYPE.ADMIN];
        else if (idTokenResult.claims.owner)
          userType = C.TYPE_INDEX[C.TYPE.OWNER];
        else if (idTokenResult.claims.press)
          userType = C.TYPE_INDEX[C.TYPE.PRESS];
        else if (idTokenResult.claims.member)
          userType = C.TYPE_INDEX[C.TYPE.MEMBER];

        newRoute = newRoute + userType;
      }
      router.replace({ name: newRoute });
    }
  },
};
const mutations = {
  setNewRoute(state, newRoute) {
    state.newRoute = newRoute;
  },
  setClaims(state, claims) {
    state.claims = claims;
  },
  setUserProfile(state, user) {
    state.user = user;
  },
  setUsername(state, name) {
    state.username = name;
  },
  login(state) {
    state.loggedIn = true;
    state.admin = false;
  },
  logout(state) {
    state.loggedIn = false;
  },
  loginAdmin(state) {
    state.loggedIn = true;
    state.admin = true;
  },
};


export default {
  state,
  getters,
  actions,
  mutations,
};
