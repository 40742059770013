import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import firebaseConfigDev from './firebaseConfigDev';
import firebaseConfig from './firebaseConfig';
import C from "@/const"

const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

let firebaseApp;
let db;
let auth;
let emailAuthProvider;
let storage;
// not sure if it is worth to reaftor collections etc like this
// => maybe just implement DAO architekture lateron
let userCollection;
let historyCollection;
if (!firebaseApp || !firebase.apps.length) {
    let config = C.PRODUCTION ? firebaseConfig : firebaseConfigDev;
    firebaseApp = firebase.initializeApp(config);
    db = firebaseApp.firestore();
    auth = firebaseApp.auth();
    storage = firebaseApp.storage();    
    userCollection = db.collection(C.COLLECTION.USERS);
    historyCollection = db.collection(C.COLLECTION.HISTORY);   
    // this one is a static method
    emailAuthProvider = firebase.auth.EmailAuthProvider;
}


export default {
    db,
    auth,
    emailAuthProvider,
    storage,
    userCollection,
    historyCollection,
    serverTimestamp
}
