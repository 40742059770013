import C from "@/const";

const state = {
  mode: "LOADING",
  //is dashboard mode
  isDash: false,
  //used for loading
  status: "LOADING",
  history: [],
  document: {},

  //user data
  userDoc: C.USER,
  userLabel: "",
  watchDoc: C.WATCH,
  serviceDoc: {},

};
const getters = {
  getMode: (state) => {
    console.log("mode: " + state.mode);
    return state.mode;
  },
  /* goBack: state => {
		console.log('pop');
		return state.history.pop()
	}, */
  getHistory: (state) => {
    console.log("history");
    return state.history;
  },
  getIsDash: (state) => {
    return state.isDash;
  },
  getDocument: (state) => {
    console.log("doc");
    return state.document;
  },

  getUserLabel: (state) => {
    return state.userLabel;
  },

  getUser: (state) => {
    console.log("store GET userDoc");
    return state.userDoc;
  },

  getWatch: (state) => {
    console.log("store GET watchDoc");
    return state.watchDoc;
  },

  getService: (state) => {
    console.log("store GET serviceDoc");
    return state.serviceDoc;
  },

  isAdmin: (state) => {
    return state.userDoc.admin;
  },

};
const mutations = {
  setDisplayMode(state) {
    console.log("store SET mode DISPLAY");
    state.mode = "DISPLAY";
  },
  setLoadingMode(state) {
    console.log("store SET mode LOADING");
    state.mode = "LOADING";
  },
  setErrorMode(state) {
    console.log("store SET mode ERROR");
    state.mode = "ERROR";
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setIsDash(state, mode) {
    console.log("store SET isDash");
    state.isDash = mode;
  },
  setDoc(state, doc) {
    state.document = doc;
  },

  //user data
  setUser(state, userDoc) {
    console.log("store SET userDoc");
    state.userLabel = userDoc.firstName.charAt(0);
    state.userDoc = userDoc;
  },
  setWatch(state, watchDoc) {
    console.log("store SET watchDoc");
    state.watchDoc = watchDoc;
  },
  setService(state, doc) {
    console.log("store SET serviceDoc");
    state.serviceDoc = doc;
  },

  //history
  addHistory(state, historyTag) {
    console.log("push: " + historyTag);
    state.history.push(historyTag);
  },
  clearHistory(state) {
    state.history = [];
  },

  // All the update functions: one generic one to be able to create new fields
  updateDoc(state, keyValuePair) {
    // had to add the comparision with 0 in order to be able to store 0s
    if (
      !keyValuePair ||
      !keyValuePair.key ||
      (keyValuePair.value !== 0 && !keyValuePair.value)
    )
      return;

    state.document[keyValuePair.key] = keyValuePair.value;
  },

  finishProcess(state) {
    state.mode = C.MODE.DEFAULT;
    state.document = {};
    state.biens = [];
  },

};

export default {
  state,
  getters,
  mutations,
};
