export default {

    // user status array
    STATUS_INDEX: [
        "unverified", // 0
        "active", // 1
        "grace period", // 2
        "on hold", // 3
        "cancelled", // 4
        "inactive", // 5
        "expired", // 6
        "payment failed", // 7
        "payment pending", //8
        "suspended", // 9
        "approved", //10
        "approval pending", //11
        "in delivery", // 12
        "declined" // 13
    ],
    // user status object array
    STATUS: {
        UNVERIFIED: 0,
        ACTIVE: 1,
        GRACE_PERIOD: 2,
        ON_HOLD: 3,
        CANCELLED: 4,
        INACTIVE: 5,
        EXPIRED: 6,
        PAYMENT_FAILED: 7,
        PAYMENT_PENDING: 8,
        SUSPENDED: 9,
        APPROVED: 10,
        APPROVAL_PENDING: 11,
        IN_DELIVERY: 12,
        DECLINED: 13
    },
    // user status color
    USER_STATUS_COLORS: [
        "badge-warning",
        "badge-success",
        "badge-warning",
        "badge-danger",
        "badge-danger",
        "badge-danger",
        "badge-warning",
        "badge-danger",
        "badge-success",
        "badge-warning",
        "badge-success",
        "badge-danger",
        "badge-danger",
        "badge-danger"
    ],


    //----- SERVICE CATALOG(SKU)/APPOINTMENTS -----------------------------------------------------------------------

    // catalogue service status
    SERVICE_INDEX: ["Active", "Inactive"],
    SERVICES: {
        ACTIVE: 0,
        INACTIVE: 1
    },



    // appointment status
    APPOINTMENT_STATUS_INDEX: [
        "unconfirmed",
        "confirmed",
        "watch received",
        "in progress",
        "work completed",
        "in delivery",
        "completed",
        "cancelled",
        "cancellation pending",
        "declined"
    ],
    APPOINTMENT_STATUS: {
        UNCONFIRMED: 0,
        CONFIRMED: 1,
        WATCH_RECEIVED: 2,
        IN_PROGRESS: 3,
        WORK_COMPLETED: 4,
        IN_DELIVERY: 5,
        COMPLETED: 6,
        CANCELLED: 7,
        CANCELLATION_PENDING: 8,
        DECLINED: 9
    },
    // APPOINTMENT_STATUS_COLORS: [
    //     "badge-danger",
    //     "badge-warning",
    //     "badge-warning",
    //     "badge-warning",
    //     "badge-warning",
    //     "badge-warning",
    //     "badge-success",
    //     "badge-danger",
    //     "badge-danger",
    //     "badge-danger"
    // ],

    //----- WARRANTY-----------------------------------------------------------------------

    // warranty statuses array
    WARRANTY_STATUS_INDEX: [
        "unverified", // 0
        "active", // 1
        "pending", // 2
        "cancelled", // 3
        "expired" // 4
    ],
    // warranty statuses object
    WARRANTY_STATUS: {
        UNVERIFIED: 0,
        ACTIVE: 1,
        PENDING: 2,
        CANCELLED: 3,
        EXPIRED: 4
    },

    //----- NEWS -----------------------------------------------------------------------

    // news article status
    NEWS_STATUS_INDEX: ["Published", "Unpublished"],
    NEWS_STATUS: {
        PUBLISHED: 0,
        UNPUBLISHED: 1
    },


    //----- UTILITIES -----------------------------------------------------------------------
    //active status

    //used to display a green status or a red status box
    isActiveUserStatus: function (status) {
        if (status == 0 || status == 1 || status == 2 || status == 10 || status == 12) return true;
        else return false;
    },

    //warranty active status
    isActiveWarrantyStatus: function (status) {
        if (status == 1) return true;
        else return false;
    },


    /**
     * NEW STATUSES
     * 
     * using 0 as the worst case status (WCS) lets us handle missing status as WCS
     * e.g.: if (!watch.status) {...treat watch as canceled...}
     */

    USER: {
        ARCHIVED: 0,
        ACTIVE: 1
    },

    PRESS_VERIFICATON: {
        UNVERIFIED: 0,
        VERIFIED: 1,
        DECLINED: 2,
    },
    PRESS_VERIFICATON_STRINGS: [
        "Unverified",
        "Verified",
        "Declined",
    ],
    WATCH: {
        CANCELED: 0,
        PAID: 1,
        IN_DELIVERY: 2,
        OWNED: 3,
        IN_SERVICE: 4,
    },
    WATCH_STRINGS: [
        "Canceled",
        "Paid",
        "In Delivery",
        "Owned",
        "In Service",
    ],
    // note that watch model also can be active/inactive (which determines if it is shown at all in the shop)
    WATCH_MODEL_AVAILABILITY: {
        BUY: 0,
        WAITING_LIST: 1,
        OUT_OF_STOCK: 2,
        INQUIRY: 3
    },
    WATCH_MODEL_AVAILABILITY_STRIGNS: [
        "Buy",
        "Waiting list",
        "Out of stock",
        "Inquiry",
    ],

    APPOINTMENT: {
        REQUESTED: 0,
        CONFIRMED: 1,
        IN_SHOP: 2,
        IN_PROGRESS: 3,
        TREATED: 4,
        IN_DELIVERY: 5,
        COMPLETED: 6,
        CANCELED: 7,
        DECLINED: 8,
    },
    APPOINTMENT_STRINGS: [
        "Requested",
        "Confirmed",
        "In Shop",
        "In Progress",
        "Treated",
        "In Delivery",
        "Completed",
        "Canceled",
        "Declined",
    ],
    APPOINTMENT_STATUS_COLORS: [
        "badge-warning",
        "badge-warning",
        "badge-warning",
        "badge-warning",
        "badge-warning",
        "badge-warning",
        "badge-success",
        "badge-danger",
        "badge-danger"
    ],

    PAYMENT: {
        CANCELED: 0,
        PROCESSING: 1,
        SUCCEEDED: 2,
        UNSUCCESSFUL: 3,
    },
    // TODO: lower- vs uppercase? => stripe uses them as all-lowercase strings
    PAYMENT_STRINGS: [
        'canceled',
        'processing',
        'succeeded',
        'unsuccessful',
    ],

    WARRANTY: {
        INACTIVE: 0,
        ACTIVE: 1,
        EXPIRED: 2,
    },
    WARRANTY_STRINGS: [
        'Inactive',
        'Active',
        'Expired',
    ]

}

