<template>
  <div class="Layout">
    <Navbar></Navbar>
    <router-view></router-view>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>

import Navbar from "@/components/navigation/NavbarPublic";
// import Footer from "@/components/navigation/Footer";

export default {
  name: "Layout",
  components: {
    Navbar: Navbar,
  },
  methods: {
  },
  computed: {
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/fonts-import.css";
@import "../assets/css2/general.css";
@import "../assets/css2/modal-vue.css";
@import "../assets/css2/club.css";
@import "../assets/css2/collection.css";
@import "../assets/css2/navbar.css";
@import "../assets/css2/dashboard-comingsoon.css";
@import "../assets/css2/watch-card.css";
@import "../assets/css2/filters.css";
@import "../assets/css2/duke-spinner.css";

// old css
@import "../assets/css/dialog-dark.css";
@import "../assets/css/duke.css";
</style>
