// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "AIzaSyBtkavpCfiBWJgaZzB-uggC3fUX9xGJvpM",
  // authDomain: "dukeplatform-f32a5.firebaseapp.com",
  // projectId: "dukeplatform-f32a5",
  // storageBucket: "dukeplatform-f32a5.appspot.com",
  // messagingSenderId: "624696479126",
  // appId: "1:624696479126:web:8c5863b4073c1d8712e1b7",
  // measurementId: "G-LLMRPKJFC4"

  // DUKE-test project
  apiKey: "AIzaSyD0dF5r-9ZD-n51YYnZNffM5AqiBwZayDA",
  authDomain: "duke-test-aeabe.firebaseapp.com",
  projectId: "duke-test-aeabe",
  storageBucket: "duke-test-aeabe.appspot.com",
  messagingSenderId: "220814717295",
  appId: "1:220814717295:web:24062296b2c59cb302f89a"

};

export default (firebaseConfig)
