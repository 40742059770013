const firebaseConfig = {
  apiKey: "AIzaSyD7QlKNB8Gzyzzwzh6RRdpsUgrD0fmct-o",
  authDomain: "duke-production.firebaseapp.com",
  projectId: "duke-production",
  storageBucket: "duke-production.appspot.com",
  messagingSenderId: "947361531156",
  appId: "1:947361531156:web:b200b0657fa56fc85f2e01"

  // DUKE-test project
  // apiKey: "AIzaSyD0dF5r-9ZD-n51YYnZNffM5AqiBwZayDA",
  // authDomain: "duke-test-aeabe.firebaseapp.com",
  // projectId: "duke-test-aeabe",
  // storageBucket: "duke-test-aeabe.appspot.com",
  // messagingSenderId: "220814717295",
  // appId: "1:220814717295:web:24062296b2c59cb302f89a"

};

export default (firebaseConfig)
