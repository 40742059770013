<template>
  <div>
    <Navbar></Navbar>

    <!-- LOGIN -->
    <div class="login duke-style">
      <div class="cover"></div>
      <div class="login-container">
        <!-- <img class="mb-4" src="/assets/spinners/tourbillon-grey.svg" alt=""> -->
        <p class="p4">Exclusive</p>
        <!-- <h1>Duke CLub</h1> -->
        <img id="club-logo" src="@/assets/logo/duke-club-logo-white.png" alt="Duke Club" />
        <!-- <p class="p2 p-text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. </p> -->
        <!-- <img class="mt-4" width="30%" src="/assets/spinners/tourbillon-grey.svg" alt=""> -->

        <form>
          <div class="form mt-4">
            <!-- <h4>Enter the club</h4> -->
            <h4>Login</h4>

            <div class="form-group mt-4">
              <p for="email" class="p4"><i class="fal fa-user"></i> Email</p>
              <input type="email" class="input" id="email" name="email" placeholder="" v-model="email" />
            </div>
            <div class="form-group mt-4">
              <p for="password" class="p4"><i class="fal fa-lock"></i> Password</p>
              <input
                type="password"
                class="input"
                id="password"
                name="password"
                placeholder=""
                v-model="password"
              />
            </div>
          </div>

          <div class="jbtn-container-center">
            <button type="submit" id="login_btn" class="jbtn-metro-round jbtn-white mt-4" @click="login">
              <span v-if="isLoading" key="spinner"><i class="fad fa-spinner-third fa-spin"></i></span>
              <span v-else key="button">Enter</span>
            </button>
          </div>
        </form>
        <p class="mt-3 p4 jbtn-text" @click="$bvModal.show('forgot-password-modal')">Forgot Password?</p>

        <!-- alert -->
        <b-alert v-if="loginMsg" class=" mt-5" show variant="secondary"
          ><i class="fal fa-exclamation-circle"></i>
          {{ loginMsg }}
        </b-alert>

        <!-- next slide -->
        <a class="next-slide-btn" href="#signup">
          <div class="next-slide-btn-content duke-style">
            <p class="p4">Create Account</p>
            <span><i class="fal fa-chevron-down"></i></span>
          </div>
        </a>
      </div>
    </div>

    <!-- SIGN UP REDIRECTION -->
    <div id="signup" class="signup-main duke-style">
      <div class="top">
        <h1>Join The Club</h1>
        <!-- <h3 data-toggle="modal" data-target="#infoModal" onclick="onInfoModal()"><i class="fal fa-info-circle"></i></h3> -->
        <h3
          @click="$bvModal.show('info-modal')"
          class="jbtn-info"
          data-toggle="modal"
          data-target="#infoModal"
        >
          <i class="fal fa-info-circle"></i>
        </h3>
      </div>
      <div class="bottom">
        <!-- <hr class="border" /> -->

        <router-link :to="{ name: 'MemberAccountCreate' }" class="signup-banner">
          <h4>Join as Member</h4>
          <p class="p4 wht">Create an account to gain access to the online store.</p>
        </router-link>
        <div class="signup-banner" id="owner-signup-banner">
          <div class="show-member-text">
            <h4><i class="fal fa-arrow-up"></i> Join as a Member first</h4>
          </div>
          <h4 class="owner-text">Join as Owner</h4>
          <p class="p4 wht">In order to become an owner you have to purchase a watch.</p>
        </div>
        <router-link :to="{ name: 'PressAccountCreate' }" class="signup-banner">
          <h4>Join as Press</h4>
          <p class="p4 wht">Create an account to gain access as a member of the media.</p>
        </router-link>

        <!-- <hr class="border" /> -->
      </div>
    </div>

    <!-- MODALS -->

    <!-- INFO MODAL -->
    <b-modal :hide-header="true" :hide-footer="true" id="info-modal" ref="info-modal" centered size="xl">
      <!-- Modal content-->
      <div class="jmodal-content">
        <!-- top -->
        <div class="jclose-float" @click="$bvModal.hide('info-modal')">
          <!-- <span aria-hidden="true">&times;</span> -->
          <i class="fal fa-times "></i>
        </div>
        <div class="jmodal-header">
          <!-- logo -->
          <a class="nav-brand" href="/">
            <img src="@/assets/logo/duke-logo-white.png" alt="" />
          </a>
        </div>

        <!-- main -->
        <div class="jmodal-main duke-style">
          <!-- title big -->
          <div class="jmodal-title">
            <h1 id="jmodal-icon"><i class="fal fa-info-circle"></i></h1>
            <h1>How it works</h1>
          </div>

          <p class="info-modal-content">
            As a member, you will have acces to a range of special services.
          </p>
          <p class="info-modal-details"><small> These include:</small></p>

          <p class="info-modal-details">
            <img src="@/assets/spinners/no-holes-square/tourbillon-grey.svg" alt="Tourbillon" />
            Online store experience<br /><br />
            <img src="@/assets/spinners/no-holes-square/tourbillon-grey.svg" alt="Tourbillon" /> Watch
            management (coordination of all your watch-related documents)<br /><br />
            <img src="@/assets/spinners/no-holes-square/tourbillon-grey.svg" alt="Tourbillon" />
            Tutorials<br /><br />
            <img src="@/assets/spinners/no-holes-square/tourbillon-grey.svg" alt="Tourbillon" /> Newletter and
            events
          </p>
          <p class="info-modal-details">
            <small
              >If you're interested in joining our exclusive membership program, please fill out the
              application form and submit it for review. Once your application has been approved, you'll
              receive instructions on how to access your exclusive benefits.</small
            >
          </p>
        </div>
      </div>
    </b-modal>

    <!-- RESET PASSWORD MODAL -->
    <b-modal
      :hide-header="true"
      :hide-footer="true"
      id="forgot-password-modal"
      ref="forgot-password-modal"
      centered
      title="BootstrapVue"
    >
      <div class="jdialog-top duke-style">
        <div class="close-container">
          <h4><strong>Reset Password</strong></h4>
          <span @click="$bvModal.hide('forgot-password-modal')" class="ml-auto jclose">
            <i class="fal fa-times "></i>
          </span>
        </div>
        <p>
          You will receive an email, which will allow you to reset your password. Please enter the email
          address associated with your account.
        </p>
      </div>
      <div class="jdialog-main duke-style">
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label class="p4" for="name"> <i class="fal fa-lock"></i> Your email </label>
              <input
                type="email"
                v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validEmail(emailForReset) && bluredEmailForReset
                }"
                v-on:blur="bluredEmailForReset = true"
                v-model="emailForReset"
              />
              <div class="invalid-feedback">
                Please enter your current email.
              </div>
            </div>
          </div>
        </div>

        <b-alert v-if="showInputError" show variant="danger"
          ><i class="fad fa-exclamation-circle"></i>
          {{ validationMsg }}
        </b-alert>
      </div>
      <div class="jdialog-bottom with-cancel">
        <button @click="$bvModal.hide('forgot-password-modal')" class="jbtn jbtn-small jbtn-white">
          <i class="fa fa-times"></i> Cancel
        </button>
        <button @click="sendPasswordResetEmail" class="jbtn jbtn-small jbtn-white">
          <span v-if="isSaving" key="spinner"><i class="fad fa-spinner-third fa-spin"></i></span>
          <span v-else key="button"><i class="fa fa-envelope"></i> Send</span>
        </button>
      </div>
    </b-modal>
    <!-- 
    <Navbar></Navbar>
    <div class="wrapper">
      <section id="login" class="section-duke section-dark jpadding jpadding-25">
        <div class="jcard-duke jcard-duke-large">
          <form class="login">

            <b-alert v-model="showAlert" class="login-message" variant="danger"><i
                class="fal fa-exclamation-triangle"></i>
              {{ loginMsg }}</b-alert>

            <div class="bottom-links text-center">
              <div class="jbtn-watch-link text-gold" @click="showForgotPasswordDialog">
                FORGOT PASSWORD?
              </div>
            </div>

            <div class="btn-container text-center mt-4 mb-4">
              <router-link :to="{ name: 'MemberAccountCreate' }" class="jbtn-duke jbtn-outline-gold">
                Register as member
              </router-link>
            </div>

          <div class="btn-container text-center mt-4 mb-4">
            <router-link :to="{ name: 'PressAccountCreate' }" class="jbtn-duke jbtn-outline-gold">
              Register as press
            </router-link>
          </div>

          </form>
        </div>
      </section>

    </div>
    <Footer></Footer>
   -->
  </div>
</template>

<script>
import Navbar from "@/components/navigation/NavbarPublic";
// import Footer from "@/components/navigation/Footer";
import toast from "@/assets/js/toast";
import firebase from "@/firebase/firebaseInit";
const auth = firebase.auth;

export default {
  name: "Login",
  components: {
    Navbar: Navbar
    // Footer: Footer,
  },
  data() {
    return {
      showAlert: false,
      email: "",
      password: "",
      loginMsg: "",
      emailForReset: "",
      bluredEmailForReset: false,

      isAdmin: false,
      isSaving: false,
      isLoading: false,
      showInputError: false
    };
  },
  methods: {
    showForgotPasswordDialog() {
      this.$refs["forgot-password-modal"].show();
    },
    initUpdateEmail() {
      this.isSaving = true;
      if (!this.validatePasswordResetEmail()) {
        this.isSaving = false;
        return;
      }

      this.sendPasswordResetEmail();
    },
    sendPasswordResetEmail() {
      auth
        .sendPasswordResetEmail(this.emailForReset)
        .then(() => {
          this.isSaving = false;
          //hide modal
          this.$refs["forgot-password-modal"].hide();
          //show toast
          toast.success("Password reset email sent. Check your inbox.");
        })
        .catch(error => {
          // var errorCode = error.code;
          // var errorMessage = error.message;
          toast.error("Couldn't reset password. " + error.message);
          this.isSaving = false;
        });
    },
    async login(e) {
      e.preventDefault();
      this.showAlert = false;
      this.isLoading = true;
      // check if user is logged in already which might lead to conflicts with firebase auth, vue store etc
      if (auth.currentUser) {
        await auth.signOut();
      }

      // store will handle the routing after getting the claims
      await this.$store.dispatch("setNewRouteAfterAuthStateChanged", "Dashboard");
      auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$store.commit("setUsername", auth.currentUser.displayName);
          this.$store.commit("login");
          this.isLoading = false;
          this.loginMsg = "";
          // this.loginMsg = "SUCCESS";
          //store handles navigation after onAuthStateChanged in main.js triggered it
        })
        .catch(err => {
          this.loginMsg = err.message;
          this.showAlert = true;
          this.isLoading = false;
        });
    },
    loginAdmin() {
      this.showAlert = false;
      auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$store.commit("setUsername", auth.currentUser.displayName);
          //load user doc to check if admin
          var docRef = this.$db.collection("users").doc(auth.currentUser.uid);
          docRef.onSnapshot(doc => {
            var isAdmin = doc.data().admin;
            console.log("User is Admin: ", isAdmin);
            this.$store.commit("setUser", doc.data());

            this.$store.commit("loginAdmin");
            this.loginMsg = "SUCCESS";
            this.$router.push({ name: "AdminDashboard" });
          });
        })
        .catch(err => {
          this.loginMsg = err.message;
          this.showAlert = true;
        });
    },
    setIsAdmin() {
      if (this.$route.params.type != null && this.$route.params.type === "admin") {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    },
    validatePasswordResetEmail() {
      this.bluredEmailForReset = true;
      this.emailForReset = this.emailForReset.trim().toLowerCase();
      if (!this.validEmail(this.emailForReset)) {
        return false;
      }

      return true;
    },
    validEmail(email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    }
  },
  beforeRouteUpdate(to, from, next) {
    // just use `this`
    if (to.params.type != null && to.params.type === "admin") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    next();
  },
  created() {
    this.setIsAdmin();
  }
};
</script>

<style lang="scss" scoped></style>
