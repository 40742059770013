import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import store from "./store/store.js";
import router from "./router";
import axios from "axios";
import moment from "moment";


// new css
// import "./assets/css/fonts-import.css";
// import "./assets/css2/general.css";
// import "./assets/css2/modal-vue.css";
// import "./assets/css2/club.css";
// import "./assets/css2/collection.css";
// import "./assets/css2/navbar.css";
// import "./assets/css2/dashboard-comingsoon.css";
// import "./assets/css2/watch-card.css";
// import "./assets/css2/filters.css";
// import "./assets/css2/duke-spinner.css";


// old css
// import "../assets/css/navbar.scss";
// import "./assets/css/talkii.css";
// import "./assets/css/main.css";
// import "./assets/css/platform.scss";
// import "./assets/css/switch.scss";
// import "./assets/css/dialog-dark.css";
// import "./assets/css/dialog.css";
// import "./assets/css/duke.css";


import CountryFlag from 'vue-country-flag'
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
const auth = firebase.auth;

Vue.config.productionTip = false;
Vue.use(BootstrapVue, axios);
Vue.prototype.$moment = moment;
Vue.prototype.$C = C;
Vue.component('country-flag', CountryFlag)

// this does not seem to get the firebase instance ready in time
// import "@/firebase/firebaseInit";
// => currently placed in App.vue

let app;

auth.onAuthStateChanged(user => {

  store.dispatch("updateUser", user);

  if (!app) {
    app = new Vue({
      store,
      router,
      render: h => h(App)
    }).$mount("#app");
  }

  if (!C.PRODUCTION) {
    console.log("User auth update");
    if (!auth.currentUser)
      return;

      auth.currentUser.getIdTokenResult(false).then((idTokenResult) => {
      // let userType = "None";
      // if (idTokenResult.claims.member)
      //   userType = "Member";
      // else if (idTokenResult.claims.owner)
      //   userType = "Owner";
      // else if (idTokenResult.claims.press)
      //   userType = "Press";
      // else if (idTokenResult.claims.Admin)
      //   userType = "Owner";
      console.log("User claims: ");
      console.log(idTokenResult.claims);
    })  
  }
});
