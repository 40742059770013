import STATUS from "@/status";

export default {
  /**
   * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
   * MAKE SURE THIS FLAG IS ONLY SET TO TRUE WHEN GOING TO PRODUCTION
   * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
   *
   * For any changes to firebase imports or dev & production differentiation
   * make sure to update UserCreate.vue component as well
   * (since a separate firebase instance is needed there)
   */
  // PRODUCTION: (process.env.NODE_ENV === 'production'),
  PRODUCTION: false,

  // CLUB_URL: (process.env.NODE_ENV === 'production') ? 'https://dukehorlogerie.com/' : 'http://localhost:8080/',
  // CLUB_URL: "https://v1.duke.jymbe.com/",
  CLUB_URL: "https://v2.duke.jymbe.com/",
  // CLUB_URL: "http://localhost:8080/",

  URL_PREFIX: "",
  // URL_PREFIX: "/club",
  //*************************************************************** */

  //----- Status (imported from status.js) -------------------------------------------

  STATUS,


  //----- USER -----------------------------------------------------------------------

  // user types
  TYPE_INDEX: ["Press", "Member", "Owner", "Admin"],
  TYPE: {
    PRESS: 0,
    MEMBER: 1,
    OWNER: 2,
    ADMIN: 3
  },

  //----- REDIRECTION-----------------------------------------------------------------
  RETRY_INTERVAL: 10, // seconds until we reset listener in order to be up to date more qiuckly
  CONNECTION_WARNING: 40, // seconds until we show the warning that the connection might be slow
  MAXIMUM_WAITING_TIME: 100, // maximum amount of seconds we let the user wait untile we show a button to continue without the expected result


  //----- NEWS -----------------------------------------------------------------------

  //news categories
  NEWS_CATEGORIES: ["News", "Release", "Event"],

  // types of history events
  HISTORY_CATEGORIES: {
    WATCH_CREATE: "watch_created",
    WATCH_EDIT: "watch_edited",
    WATCH_SOLD: "watch_sold"
  },

  //----- PAYMENT---------------------------------------------------------------------

  PRODUCT_TYPE: {
    WATCH: "watch",
    SERVICE: "service"
  },

  //----- UTILITIES ------------------------------------------------------------------

  // covert any array into an options array. each entry is an array item with an added value field from index
  getFormattedOptionsArray: function (input) {
    if (!input || !Array.isArray(input)) {
      console.log("getFormattedOptionsArray: CANNOT FORMAT OBJECT");
      console.log(input);
      return [];
    }
    var array = [];
    for (const [index, value] of input.entries()) {
      array.push({ value: index, text: value });
    }
    return array;
  },
  getFormattedBoolOptionsArray: function (input) {
    var array = [];
    //first item of given array is defined as true
    //TODO: change order: 0 for false
    array.push({ value: true, text: input[0] });
    array.push({ value: false, text: input[1] });
    return array;
  },

  // get a js Date from firestore timestamp
  getDateFromTimestamp: function (timestamp) {
    if (!timestamp) return null;
    return new Date(timestamp.seconds * 1000);
  },

  // get a js Date from milliseconds
  getDateStringFromMillis: function (millis) {
    if (!millis) return "-";
    let date = new Date(millis);
    // format: DD/MM/YYYYY
    return date.toLocaleDateString("fr-FR");
  },
  getTrimmedId(complete_id) {
    if (!complete_id) return "";
    return complete_id.substring(0, 12);
  },
  isInt: function (value) {
    return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
  },
  getModelLink: function (modelName) {
    var result = modelName.replace(/ /g, "-");
    return result.toLowerCase();
  },

  //----- STORE -----------------------------------------------------------------------

  // store modes (states)
  MODE: {
    DEFAULT: 0,
    BUY: 1,
    LOADING: 2,
    ERROR: 3
  },

  //----- OBJECT MODELS --------------------------------------------------------------

  //USER OBJECT
  USER: {
    firstName: "",
    lastName: ""
  },

  //WATCH CATALOG OBJECT
  WATCH_MODEL: {
    name: "",
    active: true,
    text: "",
    color: "",
    price: 0,
    releaseDate: 2022,
    status: STATUS.WATCH_MODEL_AVAILABILITY.BUY,

    en: {
      text: "",
      color: "",
      cooperation: "",
      limited: 8,
      movement: "",
      complications: "",
      dial: "",
      case: "",
      tourbillon: "",
      winding: "",
      nbOfJewels: "",
      frequency: "",
      powerReserve: "",
      diameter: "",
      thickness: "",
      finishingMovement: "",
      material: "",
      appliques: "",
      signaturePlate: "",
      indexRing: "",
      finishingDial: "",
      bezel: "",
      lugs: "",
      middleCase: "",
      container: "",
      caseBack: "",
      crown: "",
      finishingCase: ""
    },
    assets: {
      front: "",
      side: "",
      case: "",
      movement: "",
      dial: "",
      render: "",
      logo: "",
      warranty_en: "",
      warranty_fr: ""
    }
  },

  //WATCH OBJECT
  WATCH: {
    status: 1,
    userId: "",
    modelId: "",
    paymentId: "",
    serial: "",
    //history
    purchasedOn: new Date(),
    modifiedOn: new Date(),

    //warranty
    warrantyActive: 0,
    // warrantyStart: new Date(),
    // warrantyEnd: new Date(new Date().setFullYear(new Date().getFullYear() + 3)),

    //warranty slots
    warranties: [
      //warrantySlotOne
      {
        status: 1,
        start: new Date(),
        end: new Date(new Date().setFullYear(new Date().getFullYear() + 3)),
        duration: 3
      },
      //warrantySlotTwo
      {
        status: 0,
        start: "",
        end: "",
        duration: 3
      },
      //warrantySlotThree
      {
        status: 0,
        start: "",
        end: "",
        duration: 2
      }
    ],

    //delivery
    delivery: null
  },

  //SERVICE CATALOG OBJECT
  SERVICE_CATALOG: {
    name: "",
    active: true,
    text: "",
    price: 0,
    createdOn: new Date()
  },

  //SERVICE(APPOINTMENT) OBJECT
  APPOINTMENT: {
    status: STATUS.APPOINTMENT_STATUS.UNCONFIRMED,
    watchId: "",
    paymentId: "",
    modelId: "",
    serviceId: "",
    userId: "",
    modelName: "",
    serviceName: "",
    servicePrice: "",
    date: new Date(),
    createdOn: new Date(),
    modifiedOn: new Date(),
    info: "",

    /**
     * currently handled via info field => ready for admin
     * when time these fields can provide additional (optional) info
     */
    estimatedFinishDate: new Date().toISOString().substring(0, 10),
    dispatchDate: new Date().toISOString().substring(0, 10),
    deliveryDate: new Date().toISOString().substring(0, 10),
    deliveryCarrier: "",
    deliveryTrackingId: ""

    /**
     * possible additional info (unused so far)
     */
    // customerDsipatchDate: new Date(),
    // customerTrackingId: "",
    // receivedOn: new Date(),
    // finishedOn: new Date(),
    // deliveredOn: new Date(),
  },

  //WARRANTY OBJECT
  WARRANTY: {
    status: 0,
    watchId: "",
    name: "",
    start: new Date().toISOString().substring(0, 10),
    end: "",
    duration: ""
  },

  //NEWS ARTICLE OBJECT
  NEWS_ARTICLE: {
    type: 0, //category
    category: 0,
    status: 0,
    title: "",
    text: "",
    published: false,
    permissions: ["admin"],
    hasPdf: false,
    pdfUrl: "",
    customCover: false,
    hasCoverImage: false,
    coverUrl: ""
    //createdOn: firebase.firestore.FieldValue.serverTimestamp(),
    //publishedOn: firebase.firestore.FieldValue.serverTimestamp()
  },

  //NOTIFICATION OBJECT
  NOTIFICATION: {
    category: "",
    date: new Date(),
    text: "",
    isRead: false,
    readOn: new Date(),
    userId: ""
  },

  //----- COLLECTION REFERENCES -----------------------------------------------------------------------
  COLLECTION: {
    USERS: "users",
    CLAIMS_PROCESS: "process",
    WATCHES: "watches",
    WATCH_CATALOG: "watchCatalog",
    SERVICE_CATALOG: "serviceCatalogue",
    APPOINTMENTS: "appointments",
    WARRANTIES: "warranties",
    HISTORY: "history",
    NEWS: "news",
    NEWS_CATEGORIES: "newsCategories",
    PRODUCTS: "products",
    EMAILS: "emails",

    // SUBCOLLECTIONS
    CHECKOUT_SESSION: "checkout_sessions",
    PRICES: "prices",
    PAYMENTS: "payments",
    // collection("products").doc("tax_rates").collection("tax_rates")
    TAX_RATES: "tax_rates",
  },

  // database/auth fields
  FIELD: {
    PERMISSIONS: "permissions",
    MEMBER: "member",
    OWNER: "owner",
    PRESS: "press",
    ADMIN: "admin"
  },

  PRODUCT_PREFIX: "prod_",

  //----- FILTERS FOR LISTS -----------------------------------------------------------------------

  FILTER_TYPE: {
    SELECTION: 0,
    MULTI_SELECTION: 1,
    DATE: 2,
    NUMBER_RANGE: 3
  },

  //------- EMAILS --------- -----------------------------------------------------------------------
  EMAIL_TYPE: {
    VERIFY: "verification",
    DELIVERY_REQUIREMENT: "deliveryRequirement",
  },

  CONTACT_TYPE: {
    EMAIL: 0,
    PHONE: 1,
    WHATSAPP: 2,
  },

  // templates
  // EMAIL_VERIFICATION: {
  //   type: VERIFY,
  //   press: false, // only needs to be set to differntiate special email verification mail for press
  //   actionLink: "", // CLUB_URL
  //   email: "" // auth.currentUser.email
  // }
  // EMAIL_VERIFICATION: {
  //   type: DELIVERY_REQUIREMENT,
  //   userId: auth.currentUser.uid,
  //   email: auth.currentUser.email
  //   countryCode: "LU",
  //   contact: CONTACT_TYPE.EMAIL,
  // }
 
};
